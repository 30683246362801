<!-- Localized -->
<template>
<ModalContent
  name="field-settings-modal"
  ref="modal"
  width="640px"
  v-bind="$attrs"
  v-on="$listeners"
  :showFooter="false"
  :showHeader="false"
  @closed="reset"
>
  <template #content v-if="data">
    <div class="field-setting flex flex-col items-stretch gap-4 w-full text-base-content">
      <!-- <SearchField
        :placeholder="$t('general.search')"
        v-model="searchString"
      /> -->
      <FieldList
        :field="data.field"
        :selectedFieldId="selectedField ? selectedField.id : ''"
        :getDependentsMethod="getDependentsMethod"
        :getOptionsMethod="getOptionsMethod"
        :searchString="searchString"
        ref="fieldList"
        @select:field="handleSelectedField"
      />
      <div v-if="selectedField" class="field-settings__attributes flex flex-col gap-4">
        <div class="field-settings__title flex w-full items-center gap-4 justify-between">
          <h2 class="font-bold">
            {{ $t('components.modals.cascaded_fields_attributes') }}
          </h2>
          <span
              class="cursor-pointer cross fill-current text-gray-800 w-4"
              @click="handleSelectedField(null)"
              v-if="shouldRenderContent"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="w-full text-base-content fill-current"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
          </span>
        </div>
        <FieldAttributes
          :field="selectedField"
          :roles="roles"
          :key="selectedField.id"
          @permissionChange="permissionChange"
          @selectAllPermissions="selectAllPermissions"
          @change="attributesChanged"
          @changeCaseCreation="changeCaseCreation"
          @changeblockVisibile="changeblockVisibile"
          :queryData="queryData"
        />
        <Button
          v-if="showSave"
          @click="updateDependentField(selectedField)"
          :text="$t('components.modals.update_field')"
          class="btn-primary ml-auto"
        />
      </div>
    </div>
  </template>
</ModalContent>
</template>

<script>
import ModalContent from "@shared/modal-content";
import FieldList from "@shared/components/cascaded-fields-list"
import Button from "@/components/button";
// import SearchField from '@shared/sc-journey/search-field.vue';

const FieldAttributes = () => import('@shared/components/cascaded-field-attributes');

export default {
  name: 'field-settings-modal',
  components: {
    ModalContent,
    FieldList,
    Button,
    // SearchField,
    FieldAttributes,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    queryData: {
      type: Object,
      default: () => ({}),
    },
    getDependentsMethod: {
      type: Function,
    },
    getOptionsMethod: {
      type: Function,
    },
    updateDependentFieldMethod: {
      type: Function,
    },
    changeCaseCreationMethod: {
      type: Function,
    },
    changeblockVisibileMethod: {
      type: Function,
    },
    roles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedField: null,
      fieldsToSave: {},
      searchString: '',
    }
  },
  computed: {
    showSave() {
      if (!this.selectedField) return false;
      return !!this.fieldsToSave[this.selectedField.id];
    },
    shouldRenderContent() {
    return !this.queryData || this.queryData.action !== 'view';
  }
  },
  methods: {
    closeModal() {
      this.$modal.hide('field-settings-modal');
    },
    showModal() {
      this.$modal.show('field-settings-modal');
    },
    reset() {
      this.handleSelectedField(null);
    },
    handleSelectedField(payload) {
      this.selectedField = payload?.field || payload;
      this.selectedField.permissions = this.selectedField.permissions.map(field => {
        let obj = field;
        obj.select_all = field.role_mandatory || field.role_read || field.role_visible || field.role_write;
        return obj;  
      }) 
    },
    permissionChange(role) {
      // till now the code is not complete here, don't know why
      // added the remaning code
      this.selectedField?.permissions?.map((el) => {
        if (el.role_id === role.id) {
          if (role.role_mandatory) {
            el.role_read = true;
            el.role_write = true;
            el.role_visible = true;
            el.role_mandatory = true;
            el.select_all = true;
          } else if (role.role_write) {
            el.role_read = true;
            el.role_write = true;
            el.role_visible = true;
            el.role_mandatory = false;
            el.select_all = false;
          } else if (role.role_read) {
            el.role_read = true;
            el.role_visible = true;
            el.select_all = false;
            el.role_write = false;
          } else if (!role.role_read && role.role_visible) {
            el.role_read = false;
            el.role_visible = true;
          } else {
            el.role_visible = false;
          }
        }
      });
    },
    selectAllPermissions(role) {
      this.selectedField.permissions.map((el) => {
          // NOTE: FIX THIS
          if (el.role_id === role.id) { //before fix ==> el.id === role.id (DEL-3415)
              if (role.select_all) {
                  el.role_read = true;
                  el.role_write = true;
                  el.role_visible = true;
                  el.role_mandatory = true;
                  el.select_all = true;
              } else {
                  el.role_read = false;
                  el.role_write = false;
                  el.role_visible = false;
                  el.role_mandatory = false;
                  el.select_all = false;
              }
          }
      });
    },
    async updateDependentField(payload) {
      await this.updateDependentFieldMethod(payload);
      this.fieldsToSave[payload.id] = false;
    },
    attributesChanged() {
      this.$set(this.fieldsToSave, this.selectedField.id, true);
    },
    async changeCaseCreation() {
      this.changeCaseCreationMethod(this.selectedField);
    },
    async changeblockVisibile() {
      this.changeblockVisibileMethod(this.selectedField);
    },
  }
}
</script>

<style lang="scss" scoped>
.field-settings {
  &__option {
    &:not(:last-child) {
      @apply border-b;
    }
  }
}
</style>