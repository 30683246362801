<!-- Localized -->
<template>
<ModalContent
  name="gap-validation-modal"
  ref="modal"
  width="640px"
  :styles="{'min-height': '200px'}"
  v-bind="$attrs"
  v-on="$listeners"
  :showFooter="false"
>
  <template #title>{{ $t('components.modals.gap_timeline_attributes') }}</template>
  <template v-if="data">
    <div class="w-full p-3 font-semibold">
      <CheckGapValidationInput
        v-if="data.field && data.field.use_in_gap_validation && isBlockGap"
        :valid_period_prop="data.field.valid_gap"
        :history_prop="data.field.history"
        :showSave="true"
        :key="data.field.id"
        @handleGapValidationPayload="changed = true"
        @onGapValidationPayload="save"
        onlyContent
      />
      <GapTimeLineForBlock v-else 
        :dateTypeFields="dateTypeFields" 
        :currentBlockField="data.field" 
        @updateActiveTab="$emit('updateActiveTab', $event)"
        @updateOption="$emit('updateOption', $event)" 
        @updateTillPreset="$emit('updateTillPreset', $event)" 
        @handleUpdateBlockFieldGapTimeLine="$emit('handleUpdateBlockFieldGapTimeLine')"  />
    </div>
  </template>
</ModalContent>
</template>

<script>
import ModalContent from "@shared/modal-content";
const CheckGapValidationInput = () => import("@/pages/check-admin/checkGapValidationInput.vue"); // TODO move to @shared
const GapTimeLineForBlock = () => import('@shared/gapTimeLine')

export default {
  name: 'gap-validation-modal',
  components: {
    ModalContent,
    CheckGapValidationInput,
    GapTimeLineForBlock,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    updateFieldMethod: {
      type: Function,
    },
    dateTypeFields:{
      type:Array,
      default: () => []
    },
    isBlockGap:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      changed: false,
    }
  },
  computed: {
    showSave() {
      if (!this.data?.field) return false;
      return !!this.changed;
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide('gap-validation-modal');
    },
    showModal() {
      this.$modal.show('gap-validation-modal');
    },
    async save(payload) {
      let { section, field } = this.data;
      field = { ...field, ...payload };
      await this.updateFieldMethod(section, field);
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  input {
    // @apply w-32 relative;
    text-align: center;
    &[type='number']::-webkit-inner-spin-button {
      position: absolute;
      width: 12.5%;
      height: 100%;
      top: 0;
      right: 2px;
    }
  }
}
</style>